<template>
    <el-dialog
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible"
        custom-class="upload-dialog"
        title="头像上传"
        width="700px"
    >
        <div class="body-wrap">
            <div class="top">
                <div class="top-btn">
                    <i class="el-icon-upload"></i>上传图片
                    <input
                        @change="getFile"
                        accept="image/*"
                        name="uploadFileAvatar"
                        ref="imginput"
                        type="file"
                    />
                </div>
                <p class="top-tip">仅支持Jpg,png格式，图片小于2M，尺寸大于200*200像素。（滚动鼠标可以缩放你上传的图片）</p>
            </div>
            <!-- :centerBox="true"  :full="true" -->
            <div class="mian">
                <div class="main-l">
                    <vue-cropper
                        :autoCrop="true"
                        :autoCropHeight="198"
                        :autoCropWidth="198"
                        :canMove="true"
                        :canMoveBox="true"
                        :fixed="true"
                        :fixedBox="true"
                        :fixedNumber="[1,1]"
                        :img="imgSrc"
                        :info="true"
                        :infoTrue="true"
                        :outputSize="1"
                        :enlarge="1.01"
                        @realTime="handleRealTime"
                        outputType="jpeg"
                        ref="cropper"
                        v-if="dialogVisible"
                    ></vue-cropper>
                </div>
                <div class="main-r">
                    <div class="preview">
                        <img
                            :src="previews.url"
                            :style="previews.img"
                            v-if="previews.url"
                        />
                        <img
                            src="//hstatic.hirede.com/lbd/images/defaultUser.png"
                            style="width: 100%;height: 100%;"
                            v-else
                        />
                    </div>
                </div>
            </div>
            <!-- <img :src="imgSrc1" /> -->
        </div>

        <span
            class="dialog-footer"
            slot="footer"
        >
            <el-button @click="handleClose">取消</el-button>
            <el-button
                @click="finish"
                type="primary"
            >确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { VueCropper } from 'vue-cropper';
import UserService from '#/js/service/userService.js';
import eventBus from '#/js/util/event-bus.js';

export default {
    name: 'upload-avatar',
    components: {
        VueCropper
    },
    data () {
        return {
            dialogVisible: false,
            imgSrc: ``,
            previews: {},
            filename: "",

            // imgSrc1: "",

            option: {
                img: `https://img2.baidu.com/it/u=595244161,877631858&fm=253&fmt=auto&app=120&f=JPEG?w=650&h=560`, // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: "jpeg", // 裁剪生成图片的格式
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                // autoCropWidth: 300, // 默认生成截图框宽度
                // autoCropHeight: 200, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                // fixedNumber: [1, 1], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: false, // 截图框是否被限制在图片里面
                infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                canMove: true
            },
        };
    },
    created () { },
    mounted () { },
    methods: {
        show () {
            this.dialogVisible = true;
        },
        getFile (e) {
            var vm = this;
            console.log(e);
            // let file = this.$refs.imginput.files[0]
            const file = e.target.files[0];
            if (!/image\/\w+/.test(file.type)) {
                this.$message({
                    message: '请选择图片',
                    type: 'warning'
                });
                // 清空input值
                this.$refs.imginput.value = '';
                return false;
            }
            console.log(`size:`, file.size / 1024);
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message({
                    message: '图片大小不能超过2MB!',
                    type: 'warning'
                });
                this.$refs.imginput.value = '';
                return false;
            }

            this.filename = file.name;

            // 创建文件读取对象
            var reader = new FileReader();
            reader.readAsDataURL(file);
            // 文件读取结束
            reader.onload = function (e) {
                // 此处this === reader
                vm.imgSrc = this.result;
                // vm.previewUrl = this.result;
            };
        },
        handleRealTime (data) {
            console.log(`rt:`, data);
            // this.previews = data;
            data.img = {
                ...data.img,
                zoom: 200 / data.w,
            };
            this.previews = data;
        },
        // 上传图片到裁剪部分
        changeUpload (file, fileList) {
            // 获取文件名，后面上传的时候需要知道文件名来知道格式
            this.filename = file.name;
            // 判断图片格式
            const isIMG = ["jpg", "jpeg", "png"].some(r => file.name.endsWith(r));
            if (!isIMG) {
                this.$message("请上传图片格式!");
                return false;
            }
            // 判断图片大小不能大于2M
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message("图片大小不能超过2MB!");
                return false;
            }
            //赋值的代码放到异步
            // 上传成功后将图片地址赋值给裁剪框显示图片
            this.$nextTick(() => {
                this.cropper_visible = true;
                // 上传预览图片  是个临时的缓存地址
                this.option.img = URL.createObjectURL(file.raw);
            });
        },
        async uploadRequest (frm) {
            return new Promise((resolve, reject) => {
                UserService
                    .userUploadImage(frm)
                    .then(res => {
                        console.log(res);
                        resolve(res);
                        return shortTips('头像更换成功');
                    })
                    .catch(e => {
                        console.log(e);
                        reject(e);
                    });
            });
        },
        // 上传图片到后台
        finish () {
            // 获取当前数据流
            this.$refs.cropper.getCropBlob(async data => {
                // var imgFile = new window.File([data], Math.random(), {
                //     type: data.type,
                //     })

                // this.imgSrc1 = window.URL.createObjectURL(data)

                // console.log(`data:`, data);
                // 创造一个formdata 上传都需要formdata
                let frm = new FormData();
                // file为后台接收的参数名
                // frm.append("file", data, this.filename);
                frm.append('file', data, this.filename);
                frm.append('Purpose', 1);
                frm.append('width', 200);
                frm.append('height', 200);
                // 上传到后台
                const res = await this.uploadRequest(frm);

                this.handleClose();
                this.setParentPic();
            });
        },
        // 将文件流写入本地
        downloadFile(blob)  {
            const a = document.createElement('a') 
            a.href = URL.createObjectURL(blob) 
            console.log(a.href) 
            a.download = 'pic.png' // 下载文件的名字
            document.body.appendChild(a) 
            a.click() 
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a); 
        },
        setParentPic () {
            const _this = this;
            this.$refs.cropper.getCropData(data => {
                _this.$parent.avatarBase64 = data;
                eventBus.$emit('setAvatarBase64', data);
            });
        },
        handleClose (done) {
            //         done();
            this.imgSrc = '';
            this.previews = {};

            this.$refs.imginput.value = '';
            this.dialogVisible = false;
        },
    },
}
</script>


<style lang="scss" scope>
.body-wrap {
    .top {
        .top-btn {
            width: 110px;
            height: 32px;
            line-height: 32px;
            background: #11cd90;
            color: white;
            cursor: pointer;
            text-align: center;
            position: relative;
            border-radius: 2px;
            > input {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                font-size: 100%;
                opacity: 0;
                filter: alpha(opacity=0);
                z-index: 2;
                cursor: pointer;
            }
        }
        .top-tip {
            font-size: 14px;
            color: #666;
            line-height: 20px;
            margin: 4px auto 15px;
        }
    }
    .mian {
        // display: flex;
        padding-bottom: 20px;
        box-sizing: border-box;
        // justify-content: space-between;
        .main-l {
            display: inline-block;
            vertical-align: top;
            width: 404px;
            height: 404px;
            margin-right: 20px;
            // background: blue;
        }
        .main-r {
            display: inline-block;
            vertical-align: top;
            background: #fafafa;
            .preview {
                width: 200px;
                height: 200px;
                box-sizing: border-box;
                border: 1px #eee dashed;
                border-radius: 2px;
                overflow: hidden;
                > img {
                    max-width: none;
                    max-height: none;
                }
            }
        }
    }
}
.upload-dialog {
    .el-dialog__body {
        padding: 20px 30px 40px;
    }
}
</style>