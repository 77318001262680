var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        visible: _vm.dialogVisible,
        "custom-class": "upload-dialog",
        title: "头像上传",
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "body-wrap" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "top-btn" }, [
            _c("i", { staticClass: "el-icon-upload" }),
            _vm._v("上传图片\n                "),
            _c("input", {
              ref: "imginput",
              attrs: {
                accept: "image/*",
                name: "uploadFileAvatar",
                type: "file",
              },
              on: { change: _vm.getFile },
            }),
          ]),
          _c("p", { staticClass: "top-tip" }, [
            _vm._v(
              "仅支持Jpg,png格式，图片小于2M，尺寸大于200*200像素。（滚动鼠标可以缩放你上传的图片）"
            ),
          ]),
        ]),
        _c("div", { staticClass: "mian" }, [
          _c(
            "div",
            { staticClass: "main-l" },
            [
              _vm.dialogVisible
                ? _c("vue-cropper", {
                    ref: "cropper",
                    attrs: {
                      autoCrop: true,
                      autoCropHeight: 198,
                      autoCropWidth: 198,
                      canMove: true,
                      canMoveBox: true,
                      fixed: true,
                      fixedBox: true,
                      fixedNumber: [1, 1],
                      img: _vm.imgSrc,
                      info: true,
                      infoTrue: true,
                      outputSize: 1,
                      enlarge: 1.01,
                      outputType: "jpeg",
                    },
                    on: { realTime: _vm.handleRealTime },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "main-r" }, [
            _c("div", { staticClass: "preview" }, [
              _vm.previews.url
                ? _c("img", {
                    style: _vm.previews.img,
                    attrs: { src: _vm.previews.url },
                  })
                : _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      src: "//hstatic.hirede.com/lbd/images/defaultUser.png",
                    },
                  }),
            ]),
          ]),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.finish } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }